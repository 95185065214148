import axios from "axios";
import { useAuthStore } from '@/stores';
import VueCookies from 'vue-cookies'

export const JwtAxios = {
    get: request('GET'),
    post: request('POST'),
    delete: request('DELETE'),
    download: request('DOWNLOAD'),
    excelMulitPart: multipartRequest('POST')
};

function request(method) {
    return (path, body) => {
        let url = process.env.VUE_APP_API_URL + path
        const requestOptions = {
            headers: authHeader(),
            responseType: 'json'
        };
        let localMethod = method;
        if(method == 'GET' && body){
            url += "?" + jsonToQuery(body)
        }else if ((method=='POST' || method=='PUT' || method=='DELETE' || method=='PATCH') && body) {
            requestOptions.headers['Content-Type'] = 'application/json charset=utf-8';
            requestOptions.body = JSON.stringify(body);
        }
        else if(method == "DOWNLOAD" && body) {
            localMethod = "GET"
            url += "?" + jsonToQuery(body)
            requestOptions.headers['accept'] = 'text/plain';
            requestOptions.responseType = 'arraybuffer'
        }
        return axios({
            method: localMethod,
            url,
            data:body,
            headers: requestOptions.headers,
            responseType : requestOptions.responseType
        }).then(handleResponse).catch(handleError);
    }
}
function multipartRequest(method) {
    return (path, fd) => {
        let url = process.env.VUE_APP_API_URL + path
        const requestOptions = {
            headers: authHeader(),
        };
        requestOptions.headers['accept'] = 'text/plain';
        requestOptions.headers['Content-Type'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        return axios({
            method,
            url,
            data:fd,
            headers: requestOptions.headers,
        }).then(handleResponse).catch(handleError);
    }
}


function authHeader() {
    let resultModel = {};
    const { accessToken } = useAuthStore();
    if (accessToken != null) {
        resultModel = { Authorization: `Bearer ${accessToken}` };
    }
    return resultModel;
}


function handleResponse(response) {
    const data = response.data
    if (response.status != 200) {
        const { refreshToken, logout } = useAuthStore();
        if ([401, 403].includes(response.status) && refreshToken) {
            logout();
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

function handleError(error){
    let response = error.response
    if( response == undefined) { return false }
    let data = response.data
    if ([401, 403].includes(response.status)) {
        const { refreshToken, logout } = useAuthStore();
        logout();
    }

    return data
}

function jsonToQuery(param) {
    if (typeof param != "object") {
        return false
    }

    var str = ""
    for (var key in param) {
        str += `${key}=${param[key]}&`
    }

    return str
}