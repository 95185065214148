<template>
  <div style="padding-top:100px;">
    <section class="hero-sec">
      <div class="daycount-container container d-flex justify-content-center">
        <div class="dayDiv">
          <label>오늘 등록수</label>
          <p>{{
              (naverPlaceItemTotalCount.today + openMarketItemTotalCount.today).toLocaleString()
            }} <span>건</span></p>
        </div>
        <div class="dayPannel"/>
        <div class="dayDiv">
          <label>어제 등록수</label>
          <p>{{
              ( + naverPlaceItemTotalCount.yesterday + openMarketItemTotalCount.yesterday).toLocaleString()
            }} <span>건</span></p>
        </div>
        <div class="dayPannel"/>
        <div class="dayDiv">
          <label>이번달 등록수</label>
          <p>{{
              ( naverPlaceItemTotalCount.monthday + openMarketItemTotalCount.monthday).toLocaleString()
            }}<span>건</span></p>
        </div>
        <div class="dayPannel"/>
        <div class="dayDiv">
          <label>전체 등록수</label>
          <p>
            <ICountUp
              :delay="delay"
              :endVal="(naverPlaceItemTotalCount.total + openMarketItemTotalCount.total)"
              :options="options"
              @ready="onReady"
              class="countBase"
           >
            </ICountUp>
            <span class="countBaseSub">건</span>
          </p>
        </div>
      </div>
    </section>

    <section class="table-one-sec table-two-sec">
      <div class="container">
        <div class="row">
          <div v-if="isOpenMarketLoding" class="col-12">
            <b-skeleton-table
                :rows="5"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </div>
          <div v-else class="col-12">
            <div class="table-one-inner">
              <div class="table-one-inner1x">
                <img src="/assets/images/type1.png" alt="">
                <h6>오픈마켓 DB</h6>
                <p>
                  오픈마켓을 구분하여 DB를 추출 할 수 있습니다.
                </p>
              </div>
              <div class="table-one-inner2x">
                <h6>
                  업체수 : <span>{{ openMarketItemTotalCount.total.toLocaleString() }}</span>
                </h6>
                <h5>
                  {{ $dayjs().format('MM월DD일자 업데이트') }} : <span>{{
                    openMarketItemTotalCount.today.toLocaleString()
                  }}건</span>
                </h5>
              </div>
            </div>
            <div class="table-one-content">
              <table class="table">
                <thead>
                <tr>
                  <th scope="col">업체명</th>
                  <th scope="col">카테고리</th>
                  <th scope="col">전화번호</th>
                  <th scope="col">분류</th>
                  <th scope="col">업데이트</th>
                  <th scope="col">확인</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in openMarketItemModel">

                  <td style="width: 250px">
                    <p @click="copyText(item.title)">{{ item.title }} <img @click="copyText(item.title)" class="blink-effect-1s" src="/assets/images/new_icon.png" alt=""></p>
                  </td>
                  <td style="width: 200px;">
                    <p>{{ item.depth }}</p>
                  </td>
                  <td style="width: 130px;">
                    <p>
                      <img v-if="item.phone != ''" src="/assets/images/phone2.png" alt="">
                      {{ item.phone }}
                    </p>
                  </td>
                  <td class="n-td" style="width: 120px;">
                    <p>
                      <span :class="{
                         'n-name-ss': item.market=='N사',
                        'n-name-a': item.market=='A사',
                        'n-name-g': item.market=='G사',
                        'n-name-c': item.market=='C사',
                      }">{{ item.market }}</span>
                    </p>
                  </td>
                  <td style="width: 130px;">
                    <p>{{ $dayjs(item.createDate).format('YYYY-MM-DD') }}</p>
                  </td>
                  <td style="width: 60px;">
                    <p><img  @click="GetLink('오픈마켓',item.link)" src="/assets/images/point.png" alt=""></p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="table-one-sec table-two-sec">
      <div class="container">
        <div class="row">
          <div v-if="isNaverPlaceLoding" class="col-12">
            <b-skeleton-table
                :rows="5"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </div>
          <div v-else class="col-12">
            <div class="table-one-inner">
              <div class="table-one-inner1x">
                <img src="/assets/images/type2.png" alt="">
                <h6>사업자 DB</h6>
                <p>
                  분류로 DB를 추출 할 수 있습니다.
                </p>
              </div>
              <div class="table-one-inner2x">
                <h6>
                  업체수 : <span>{{ naverPlaceItemTotalCount.total.toLocaleString() }}</span>
                </h6>
                <h5>
                  {{ $dayjs().format('MM월DD일자 업데이트') }} : <span>{{
                    naverPlaceItemTotalCount.today.toLocaleString()
                  }}건</span>
                </h5>
              </div>
            </div>
            <div class="table-one-content">
              <table class="table">
                <thead>
                <tr>
                  <th scope="col">업체명</th>
                  <th scope="col">주소</th>
                  <th scope="col">분류</th>
                  <th scope="col">전화번호</th>
                  <th scope="col">업데이트</th>
                  <th scope="col">확인</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in naverPlaceItemModel">

                  <td style="width: 250px">
                    <p @click="copyText(item.title)">{{ item.title }} <img @click="copyText(item.title)" class="blink-effect-1s" src="/assets/images/new_icon.png" alt=""></p>
                  </td>
                  <td style="width: 400px;">
                    <p>{{ item.address }}</p>
                  </td>
                  <td style="width: 250px;">
                    <p>
                      {{item.depth}}
                    </p>
                  </td>
                  <td style="width: 150px;">
                    <p>
                      <img src="/assets/images/phone2.png" alt="">
                      {{ item.phone }}
                    </p>
                  </td>
                  <td style="width: 130px;">
                    <p>{{ $dayjs(item.createDate).format('YYYY-MM-DD') }}</p>
                  </td>
                  <td style="width: 60px;">
                    <p><img @click="GetLink('사업자',item.link)" src="/assets/images/point.png" alt=""></p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>

</template>

<script>
import {oepnMarketAPI, naverPlaceAPI } from '@/api/index';
import ICountUp from 'vue-countup-v2';

export default {
  components: {
    ICountUp
  },
  data() {
    return {
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      },
      delay: 1000,
      isOpenMarketLoding: false,
      openMarketItemModel: [],
      openMarketItemModelCount: 0,
      openMarketItemTotalCount: {
        monthday: 0,
        today: 0,
        total: 0,
        yesterday: 0
      },

      isNaverPlaceLoding: false,
      naverPlaceItemModel: [],
      naverPlaceItemModelCount: 0,
      naverPlaceItemTotalCount: {
        monthday: 0,
        today: 0,
        total: 0,
        yesterday: 0
      },

    }
  },
  watch: {
    async user(data) {
      await this.GetOpenMarket()
      await this.GetNaverPlace()
    }
  },

  async created() {
    this.isOpenMarketLoding = true
    this.isNaverPlaceLoding = true
    await this.GetNaverPlace()
    await this.GetNaverPlaceTotalCount()
    this.isNaverPlaceLoding = false
    await this.GetOpenMarket()
    await this.GetOpenMarketTotalCount()
    this.isOpenMarketLoding = false
  },
  methods: {
    onReady: function(instance, CountUp) {
      const that = this;
      instance.update((this.naverPlaceItemTotalCount.total + this.openMarketItemTotalCount.total) + 100);
    },
    async GetOpenMarket() {
      const itemResponse = await oepnMarketAPI.GetOpenMarket(1)
      if (itemResponse.success == true) {
        this.openMarketItemModel = itemResponse.data
      }
    },
    async GetOpenMarketTotalCount() {
      const itemResponse = await oepnMarketAPI.GetOpenMarketTotalCount()
      if (itemResponse.success == true) {
        this.openMarketItemTotalCount = itemResponse.data
      }
    },
    async GetNaverPlace() {
      const itemResponse = await naverPlaceAPI.GetNaverPlace(1)
      if (itemResponse.success == true) {
        this.naverPlaceItemModel = itemResponse.data
      }
    },
    async GetNaverPlaceTotalCount() {
      const itemResponse = await naverPlaceAPI.GetNaverPlaceTotalCount()
      if (itemResponse.success == true) {
        this.naverPlaceItemTotalCount = itemResponse.data
      }
    },
    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
      } catch (error) {
      }
    },

    GetLink(type,link){
      if(link == null || link ==""){
        alert('로그인 후 사용 부탁드립니다.')
        return
      }
      else if(type =="오픈마켓" && link.indexOf('/') == -1){
        window.open(`https://smartstore.naver.com/${link}`);
      }
      else if (type=="오픈마켓"){
        window.open(link);
      }
      else if(type=="사업자"){
        window.open(`https://map.naver.com/p/entry/place/${link}`);
      }
    },


  }
}
</script>

<style scoped>
.daycount-container {
  background: white;
  border-radius: 20px;
  padding: 16px 0px 16px 0px;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.1);
}

.daycount-container .dayPannel {
  width: 0px;
  height: 65px;
  border: 1px solid rgba(192, 192, 192, 1);
  margin-top: 7px;
  margin-left: 52px;
  margin-right: 52px;
}

.daycount-container .dayDiv label {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: rgba(52, 52, 52, 1);
}

.daycount-container .dayDiv p {
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: rgba(195, 66, 255, 1);
  font-weight: 700;
  margin: 0px;
}

.daycount-container .dayDiv p > span {
  font-size: 24px;
  font-width: 500;
  color: black;
}

.countBase{
  width: 100%;
  text-align: center;
  font-size: 30px !important;
  color: rgba(195, 66, 255, 1) !important;
  font-weight: 700;
  margin: 0px;
}
.countBaseSub{
  font-size: 24px;
  font-width: 500;
  color: black;
}

@media (max-width: 1000px) {

  .daycount-container .dayDiv label {
    width: 100%;
    text-align: center;
    font-size: 13px;
    color: rgba(52, 52, 52, 1);
  }
  .daycount-container .dayDiv p {
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: rgba(195, 66, 255, 1);
    font-weight: 700;
    margin: 0px;
  }
  .countBase{
    width: 100%;
    text-align: center;
    font-size: 24px !important;
    color: rgba(195, 66, 255, 1) !important;
    font-weight: 700;
    margin: 0px;
  }
  .countBaseSub{
    font-size: 13px;
    font-width: 500;
    color: black;
  }
}

@media (max-width: 800px) {

  .daycount-container .dayDiv label {
    width: 100%;
    text-align: center;
    font-size: 10px;
    color: rgba(52, 52, 52, 1);
  }
  .daycount-container .dayDiv p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: rgba(195, 66, 255, 1);
    font-weight: 700;
    margin: 0px;
  }

  .daycount-container .dayDiv p > span {
    font-size: 14px;
    font-width: 500;
    color: black;
  }
  .countBase{
    width: 100%;
    text-align: center;
    font-size: 14px !important;
    color: rgba(195, 66, 255, 1) !important;
    font-weight: 700;
    margin: 0px;
  }
  .countBaseSub{
    font-size: 10px;
    font-width: 500;
    color: black;
    font-size: 14px !important;
  }

  .daycount-container .dayPannel {
    width: 0px;
    height: 50px;
    border: 1px solid rgba(192, 192, 192, 1);
    margin-top: 7px;
    margin-left: 18px;
    margin-right: 18px;
  }
}
</style>