import {JwtAxios} from "@/packaging";
import dayjs from "dayjs";

export const oepnMarketAPI = {
    async GetOpenMarket(page,limit=15,startDate="",endDate="", keywordType="",keyword="",market=""){
        if(startDate=="" || endDate =="") {
            startDate='1990-01-01'
            endDate= dayjs().format('YYYY-MM-DD')
        }
        const responseModel = await JwtAxios.get(`/OpenMarket/GetOpenMarket?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&keywordType=${keywordType}&keyword=${keyword}&market=${market}`);
        return responseModel
    },
    async GetOpenMarketCount(startDate="",endDate="", keywordType="",keyword="",market=""){
        if(startDate=="" || endDate =="") {
            startDate='1990-01-01'
            endDate= dayjs().format('YYYY-MM-DD')
        }
        const responseModel = await JwtAxios.get(`/OpenMarket/GetOpenMarketCount?startDate=${startDate}&endDate=${endDate}&keywordType=${keywordType}&keyword=${keyword}&market=${market}`);
        return responseModel
    },

    async GetOpenMarketTotalCount(){
        const responseModel = await JwtAxios.get(`/OpenMarket/GetOpenMarketTotalCount`);
        return responseModel
    },

    async ExportExcel(model){
        const responseModel = await JwtAxios.download(`/OpenMarket/ExportExcel`,model);
        return responseModel
    },
}